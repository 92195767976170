(function iife(App, window) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'ClientStorage');

  /**
   * Client Storage class
   *
   * @return {ClientStorage} The created instance
   */
  function ClientStorage() {
    if (!(this instanceof ClientStorage)) {
      return new ClientStorage();
    }

    Object.defineProperty(this, 'isEnabled', {
      get() {
        return 'localStorage' in window;
      },
    });

    log('constructor', this);

    return this;
  }

  /**
   * Get an item by key
   *
   * @param  {String} key The key to get
   * @return {Mixed}      The data saved with this key
   */
  ClientStorage.prototype.get = function get(key) {
    log('get', key);

    if (!this.isEnabled) {
      return;
    }

    // eslint-disable-next-line
		var data = window.localStorage.getItem(key);
    // eslint-disable-next-line
		return JSON.parse(data);
  };

  /**
   * Save an item in the local storage
   *
   * @param {String} key  The key in which to save the data
   * @param {Mixed}  data The data to save
   */
  ClientStorage.prototype.set = function set(key, data) {
    log('set', key, data);

    if (!this.isEnabled) {
      return;
    }

    // eslint-disable-next-line
		var json = JSON.stringify(data);
    window.localStorage.setItem(key, json);
  };

  // Expose an instance globally
  App.components.clientStorage = new ClientStorage();
})(App, window);
